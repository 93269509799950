import { default as indexsQRY0hRCGaMeta } from "/home/portfolio/actions-runner/_work/portfolio/portfolio/src/pages/_blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93XuvEs8Pj4kMeta } from "/home/portfolio/actions-runner/_work/portfolio/portfolio/src/pages/[...slug].vue?macro=true";
import { default as aboutDvbN3nN5lAMeta } from "/home/portfolio/actions-runner/_work/portfolio/portfolio/src/pages/about.vue?macro=true";
import { default as blogQs4UTZS4cVMeta } from "/home/portfolio/actions-runner/_work/portfolio/portfolio/src/pages/blog.vue?macro=true";
import { default as generate_45thumbnailuXYq5eEgsbMeta } from "/home/portfolio/actions-runner/_work/portfolio/portfolio/src/pages/generate-thumbnail.vue?macro=true";
import { default as indexATYDqCJQJBMeta } from "/home/portfolio/actions-runner/_work/portfolio/portfolio/src/pages/index.vue?macro=true";
import { default as ButtonsMnuKDAePqoMeta } from "/home/portfolio/actions-runner/_work/portfolio/portfolio/src/pages/udemy/Buttons.vue?macro=true";
import { default as index4tZQl4U6EUMeta } from "@pages/_blog/index.vue?macro=true";
export default [
  {
    name: indexsQRY0hRCGaMeta?.name ?? "_blog",
    path: indexsQRY0hRCGaMeta?.path ?? "/_blog",
    meta: { ...(indexsQRY0hRCGaMeta || {}), ...{"breadcrumbs":[{"name":"Home","path":"/"}]} },
    alias: indexsQRY0hRCGaMeta?.alias || [],
    redirect: indexsQRY0hRCGaMeta?.redirect,
    component: () => import("/home/portfolio/actions-runner/_work/portfolio/portfolio/src/pages/_blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93XuvEs8Pj4kMeta?.name ?? "slug",
    path: _91_46_46_46slug_93XuvEs8Pj4kMeta?.path ?? "/:slug(.*)*",
    meta: { ...(_91_46_46_46slug_93XuvEs8Pj4kMeta || {}), ...{"breadcrumbs":[{"name":"Home","path":"/"}]} },
    alias: _91_46_46_46slug_93XuvEs8Pj4kMeta?.alias || [],
    redirect: _91_46_46_46slug_93XuvEs8Pj4kMeta?.redirect,
    component: () => import("/home/portfolio/actions-runner/_work/portfolio/portfolio/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: aboutDvbN3nN5lAMeta?.name ?? "about",
    path: aboutDvbN3nN5lAMeta?.path ?? "/about",
    meta: { ...(aboutDvbN3nN5lAMeta || {}), ...{"breadcrumbs":[{"name":"Home","path":"/"},{"name":"about","path":"/about"}]} },
    alias: aboutDvbN3nN5lAMeta?.alias || [],
    redirect: aboutDvbN3nN5lAMeta?.redirect,
    component: () => import("/home/portfolio/actions-runner/_work/portfolio/portfolio/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: blogQs4UTZS4cVMeta?.name ?? "blog",
    path: blogQs4UTZS4cVMeta?.path ?? "/blog",
    meta: { ...(blogQs4UTZS4cVMeta || {}), ...{"breadcrumbs":[{"name":"Home","path":"/"},{"name":"blog","path":"/blog"}]} },
    alias: blogQs4UTZS4cVMeta?.alias || [],
    redirect: blogQs4UTZS4cVMeta?.redirect,
    component: () => import("/home/portfolio/actions-runner/_work/portfolio/portfolio/src/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: generate_45thumbnailuXYq5eEgsbMeta?.name ?? "generate-thumbnail",
    path: generate_45thumbnailuXYq5eEgsbMeta?.path ?? "/generate-thumbnail",
    meta: { ...(generate_45thumbnailuXYq5eEgsbMeta || {}), ...{"breadcrumbs":[{"name":"Home","path":"/"},{"name":"generate-thumbnail","path":"/generate-thumbnail"}]} },
    alias: generate_45thumbnailuXYq5eEgsbMeta?.alias || [],
    redirect: generate_45thumbnailuXYq5eEgsbMeta?.redirect,
    component: () => import("/home/portfolio/actions-runner/_work/portfolio/portfolio/src/pages/generate-thumbnail.vue").then(m => m.default || m)
  },
  {
    name: indexATYDqCJQJBMeta?.name ?? "index",
    path: indexATYDqCJQJBMeta?.path ?? "/",
    meta: { ...(indexATYDqCJQJBMeta || {}), ...{"breadcrumbs":[{"name":"Home","path":"/"},{"name":"Home","path":"/"}]} },
    alias: indexATYDqCJQJBMeta?.alias || [],
    redirect: indexATYDqCJQJBMeta?.redirect,
    component: () => import("/home/portfolio/actions-runner/_work/portfolio/portfolio/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: ButtonsMnuKDAePqoMeta?.name ?? "udemy-Buttons",
    path: ButtonsMnuKDAePqoMeta?.path ?? "/udemy/Buttons",
    meta: { ...(ButtonsMnuKDAePqoMeta || {}), ...{"breadcrumbs":[{"name":"Home","path":"/"}]} },
    alias: ButtonsMnuKDAePqoMeta?.alias || [],
    redirect: ButtonsMnuKDAePqoMeta?.redirect,
    component: () => import("/home/portfolio/actions-runner/_work/portfolio/portfolio/src/pages/udemy/Buttons.vue").then(m => m.default || m)
  },
  {
    name: index4tZQl4U6EUMeta?.name ?? "dynamicNuxtRoutes",
    path: index4tZQl4U6EUMeta?.path ?? "/blog/2024/dynamic-nuxt-routes/",
    meta: { ...(index4tZQl4U6EUMeta || {}), ...{"path":"blog/2024/dynamicNuxtRoutes.md","sha":"a4ba896e347fd2a7b68fda9546034f1d9c77ef31","title":"Dynamic routes with Nuxt","description":"My blog routes are imported from another github repo.","createdAt":"2024-05-18","readingTime":7,"toc":{"items":[{"title":" First of let's install dependencies","level":2,"hash":"#first-of-let's-install-dependencies","children":[]},{"title":" 1. Setup a content github repo","level":2,"hash":"#1.-setup-a-content-github-repo","children":[]},{"title":" 2. Get the markdown files from Github","level":2,"hash":"#2.-get-the-markdown-files-from-github","children":[{"title":" Get the files from github","level":3,"hash":"#get-the-files-from-github","children":[]}]},{"title":" 3. Setup and api path to retrieve github file as content","level":2,"hash":"#3.-setup-and-api-path-to-retrieve-github-file-as-content","children":[]},{"title":" Lets configure our View!","level":2,"hash":"#lets-configure-our-view!","children":[{"title":" index.vue","level":3,"hash":"#index.vue","children":[]}]},{"title":" GithubContent.vue","level":3,"hash":"#githubcontent.vue","children":[]}]},"createdAtTimeStamp":1715990400,"tags":["Nuxt"," Vue"," TypeScript"," Oktokit"," Github"],"category":"blog","categories":"Nuxt, Vue, TypeScript, Oktokit, Github","author":"Camilla Nyberg","image":"https://i.ibb.co/hMJ4yzG/og-cute-code-nuxt.png","draft":"false","objectID":":blog:2024:dynamic-nuxt-routes:","breadcrumbs":[{"name":"Home","path":"/"},{"name":"blog","path":"/blog"}]} },
    alias: index4tZQl4U6EUMeta?.alias || [],
    redirect: index4tZQl4U6EUMeta?.redirect,
    component: () => import("@pages/_blog/index.vue").then(m => m.default || m)
  },
  {
    name: index4tZQl4U6EUMeta?.name ?? "indexNuxtPagesToAlgolia",
    path: index4tZQl4U6EUMeta?.path ?? "/blog/2024/index-nuxt-pages-to-algolia/",
    meta: { ...(index4tZQl4U6EUMeta || {}), ...{"path":"blog/2024/indexNuxtPagesToAlgolia.md","sha":"f303ff28a36b600934fa79763fe036ae2ce435df","title":"Index Nuxt pages to Algolia","description":"We'll index our custom blog pages into an Algolia index.","createdAt":"2024-05-24","readingTime":1,"toc":{"items":[]},"createdAtTimeStamp":1716508800,"tags":["Vue"," TypeScript"," Nuxt"," Algolia"],"category":"blog","categories":"Vue, TypeScript, Nuxt, Algolia","author":"Camilla Nyberg","image":"https://i.ibb.co/FYt2LRb/og-camel.png","draft":"true","objectID":":blog:2024:index-nuxt-pages-to-algolia:","breadcrumbs":[{"name":"Home","path":"/"},{"name":"blog","path":"/blog"}]} },
    alias: index4tZQl4U6EUMeta?.alias || [],
    redirect: index4tZQl4U6EUMeta?.redirect,
    component: () => import("@pages/_blog/index.vue").then(m => m.default || m)
  },
  {
    name: index4tZQl4U6EUMeta?.name ?? "nuxtContentUrlsToCamelCase",
    path: index4tZQl4U6EUMeta?.path ?? "/blog/2024/nuxt-content-urls-to-camel-case/",
    meta: { ...(index4tZQl4U6EUMeta || {}), ...{"path":"blog/2024/nuxtContentUrlsToCamelCase.md","sha":"41bc76396b152a64c4dabfd1dd5674ef6ae59183","title":"Camel case to dash routes with Nuxt Content","description":"I will show you how you can tie into the built in hooks to turn your camel cased files into dashed slugs.","createdAt":"2024-05-20","readingTime":4,"toc":{"items":[{"title":" 1. First we need to create a Nitro plugin","level":2,"hash":"#1.-first-we-need-to-create-a-nitro-plugin","children":[]},{"title":" 2 Create function to turn camel case into dashed slug","level":2,"hash":"#2-create-function-to-turn-camel-case-into-dashed-slug","children":[]},{"title":" 3. Use your new camel case to dash function","level":2,"hash":"#3.-use-your-new-camel-case-to-dash-function","children":[]}]},"createdAtTimeStamp":1716163200,"tags":["Nuxt"," Vue"," TypeScript"," Nuxt Content"],"category":"blog","categories":"Nuxt, Vue, TypeScript, Nuxt Content","author":"Camilla Nyberg","image":"https://i.ibb.co/FYt2LRb/og-camel.png","draft":"false","objectID":":blog:2024:nuxt-content-urls-to-camel-case:","breadcrumbs":[{"name":"Home","path":"/"},{"name":"blog","path":"/blog"}]} },
    alias: index4tZQl4U6EUMeta?.alias || [],
    redirect: index4tZQl4U6EUMeta?.redirect,
    component: () => import("@pages/_blog/index.vue").then(m => m.default || m)
  },
  {
    name: index4tZQl4U6EUMeta?.name ?? "sanityOpenAiImages",
    path: index4tZQl4U6EUMeta?.path ?? "/blog/2024/sanity-open-ai-images/",
    meta: { ...(index4tZQl4U6EUMeta || {}), ...{"path":"blog/2024/sanityOpenAiImages.md","sha":"b3e901e4e026b95a74e990fbdda4eeed2f5e558d","title":"Sanity Open AI image property","description":"I will show you how you how to create an image property with the option to generate AI Images.","createdAt":"2024-07-04","readingTime":12,"toc":{"items":[{"title":" 1. Create an Open AI account","level":2,"hash":"#1.-create-an-open-ai-account","children":[]},{"title":" 2. Add fields to .env","level":2,"hash":"#2.-add-fields-to-.env","children":[]},{"title":" 3. Install OpenAI Nuget","level":2,"hash":"#3.-install-openai-nuget","children":[]},{"title":" 4. Create custom input component","level":2,"hash":"#4.-create-custom-input-component","children":[]},{"title":" 4. Imports","level":2,"hash":"#4.-imports","children":[]},{"title":" 5. Add Client and Toast","level":2,"hash":"#5.-add-client-and-toast","children":[]},{"title":" 6. Add functionality to generate AI Image","level":2,"hash":"#6.-add-functionality-to-generate-ai-image","children":[{"title":" First add state props","level":3,"hash":"#first-add-state-props","children":[]}]},{"title":" 7. Save image","level":2,"hash":"#7.-save-image","children":[]},{"title":" 8. Create Sanity Field","level":2,"hash":"#8.-create-sanity-field","children":[]},{"title":" Complete code","level":2,"hash":"#complete-code","children":[]}]},"createdAtTimeStamp":1720051200,"tags":["Sanity"," React"," Typescript"," OpenAI"],"category":"blog","categories":"Sanity, React, Typescript, OpenAI","author":"Camilla Nyberg","image":"https://i.ibb.co/ChX8C0R/AI-robot-Blog.png","draft":"false","objectID":":blog:2024:sanity-open-ai-images:","breadcrumbs":[{"name":"Home","path":"/"},{"name":"blog","path":"/blog"}]} },
    alias: index4tZQl4U6EUMeta?.alias || [],
    redirect: index4tZQl4U6EUMeta?.redirect,
    component: () => import("@pages/_blog/index.vue").then(m => m.default || m)
  },
  {
    name: index4tZQl4U6EUMeta?.name ?? "thumbnailEditor",
    path: index4tZQl4U6EUMeta?.path ?? "/blog/2024/thumbnail-editor/",
    meta: { ...(index4tZQl4U6EUMeta || {}), ...{"path":"blog/2024/thumbnailEditor.md","sha":"612ad26da9b45c20caf0c47f8493c6c2e704654e","title":"Thumbnail editor","description":"I created and online editor for Optimizely thumbnails with icons","createdAt":"2024-09-20","readingTime":1,"toc":{"items":[]},"createdAtTimeStamp":1726790400,"tags":["Optimizely"," Thumbnail"," Images"],"category":"blog","categories":"Optimizely, Thumbnail, Images","author":"Camilla Nyberg","image":"https://i.ibb.co/F4K5g4Y/editor.png","draft":"false","objectID":":blog:2024:thumbnail-editor:","breadcrumbs":[{"name":"Home","path":"/"},{"name":"blog","path":"/blog"}]} },
    alias: index4tZQl4U6EUMeta?.alias || [],
    redirect: index4tZQl4U6EUMeta?.redirect,
    component: () => import("@pages/_blog/index.vue").then(m => m.default || m)
  }
]