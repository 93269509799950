import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { defineNuxtPlugin } from 'nuxt/app'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faLaptopCode,
  faEnvelope,
  faGhost,
  faArrowUpRightFromSquare,
  faBriefcase,
  faChalkboardUser,
  faGraduationCap,
  faArrowUp,
  faArrowLeft,
  faQuoteRight,
  faAnglesRight,
  faCopy,
  faChevronLeft,
  faChevronRight,
  faChevronCircleLeft,
  faChevronCircleRight,
  faHouse,
  faBookOpen,
  faBurger,
  faArrowRight,
  faXmark,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'

library.add(
  faLaptopCode,
  faEnvelope,
  faGhost,
  faLinkedin,
  faGithub,
  faPlayCircle,
  faArrowUpRightFromSquare,
  faBriefcase,
  faChalkboardUser,
  faGraduationCap,
  faArrowUp,
  faArrowRight,
  faQuoteRight,
  faAnglesRight,
  faCopy,
  faChevronLeft,
  faChevronRight,
  faChevronCircleLeft,
  faChevronCircleRight,
  faHouse,
  faBookOpen,
  faBurger,
  faArrowLeft,
  faXmark,
  faInfoCircle
)

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon)
})
